<template>
  <div id="compte">
    <div id="zone_title">
      <h1 class="s3 bold flex-vcenter">
        <IconUserSquare />
        {{ $t("compte.votre-profil") }}
      </h1>
    </div>
    <Account
      :user="user"
      @validate-img="changeAvatar($event)"
      @submit-user="submitUser($event)"
    >
      <template #block-right>
        <ul class="text-regular">
          <li
            v-for="service in servicesDistinct"
            :key="service.code"
            v-html="service.nom"
          />
        </ul>
      </template>
    </Account>
  </div>
</template>

<script>
import { Account, IconUserSquare } from "@lde/core_lde_vue";

import Api from "@/modules/axios";

import { mapGetters } from "vuex";

/**
 * Page du profil de l'utilisateur.
 */
export default {
  name: "Home",
  components: {
    Account,
    IconUserSquare,
  },
  computed: {
    ...mapGetters(["services", "user"]),
    /**
     * Affiche chaque service accessible par l'utilisateur une seule fois, en séparant FR et DE.
     */
    servicesDistinct() {
      const servicesDistinct = [];

      this.services.forEach((service) => {
        if (!servicesDistinct.some((s) => s.groupe === service.groupe)) {
          servicesDistinct.push(service);
        }
      });

      return servicesDistinct;
    },
  },
  methods: {
    /**
     * Enregistre les données de l'utilisateur.
     * @param {Object} user Nouvelles informations de l'utilisateur.
     * @public
     */
    submitUser(user) {
      this.$store.commit("setUser", user);
      this.$toast.success({
        title: this.$t("toast.compte-mis-a-jour"),
      });
    },
    /**
     * Change l'avatar de l'utilisateur.
     * @param {Object} imgFromModal Image sélectionnée dans la modale (proposée ou upload).
     * @public
     */
    changeAvatar(imgFromModal) {
      let formdata = null;

      if (imgFromModal.checkedImg) {
        formdata = { avatar_from_library: imgFromModal.checkedImg };
      } else if (imgFromModal.uploadedFile) {
        formdata = new FormData();
        formdata.append("avatar", imgFromModal.uploadedFile);
      }

      if (formdata) {
        Api().post("/utilisateur/upload_avatar/", formdata)
          .then((res) => {
            this.$store.commit("setAvatar", res.data);
            this.$toast.success({ title: this.$t("modal-change.avatar-modifie-avec-succes") });
            this.$modal.hide("modal_change_image");
          })
          .catch((error) => {
            this.$toast.error({ title: error.response?.data?.avatar[0] || error });
          });
      }
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_compte.scss";
</style>
