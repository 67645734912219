<template>
  <div
    v-if="!allServicesAreLoading && services?.length > 1"
    id="home"
  >
    <div
      id="top_zone"
      class="grid-zone"
    >
      <div id="zone_animation">
        <span
          v-for="n in 5"
          :id="`square${n}`"
          :key="n"
        />
      </div>

      <template v-if="!allServicesAreLoading">
        <div id="titles">
          <h1 class="s4 bold">
            {{ $t('accueil.bienvenue-univers-cristal') }}
            <img
              src="@lde/core_lde_vue/dist/assets/media/logos/logo_cristal.svg"
              alt="Logo Cristal"
            />
          </h1>
          <h2 class="s6 light">
            {{ $t("accueil.les-x-modules", { nb: servicesToDisplay.main.length + servicesToDisplay.other.length, }) }}
          </h2>
        </div>
        <section id="main_services">
          <template v-if="servicesToDisplay.main?.length">
            <ButtonService
              v-for="service in servicesToDisplay.main"
              :key="service.code"
              :class="{ 'one-service': servicesToDisplay.main.length === 1, }"
              :service="service"
              :color="service.groupe === 'cristal_commandes' ? 'blue' : 'yellow'"
            />
          </template>
          <div
            v-else
            id="no_main_services"
            class="s4 light"
          >
            <p v-html="$t('accueil.vous-souhaitez-acces-catalogue-numerique')" />
            <div class="flex-vcenter">
              <template v-if="services.some((service) => service.groupe === 'hotline')">
                <p
                  class="bold"
                  v-html="$t('accueil.decouvrez-nouvel-univers')"
                />
                <ButtonClassic
                  balise-type="a"
                  variant="ghost"
                  color="primary"
                  icon="left"
                  :label="$t('general.contact')"
                  :ext-link="hotlineLink"
                >
                  <template #left-icon>
                    <UilEnvelope />
                  </template>
                </ButtonClassic>
              </template>

              <template v-else>
                <p class="bold">
                  {{ $t("accueil.decouvrez-nouvel-univers-contact") }}
                  <a
                    class="s4 light"
                    :href="`tel:${isDe ? '07851485990' : '0388391707'}`"
                  >
                    {{ isDe ? "07851 485990-0" : "03 88 39 17 07" }}
                  </a>
                  {{ $t("general.ou") }}
                </p>
                <ButtonClassic
                  variant="ghost"
                  color="primary"
                  icon="left"
                  :label="$t('accueil.par-mail')"
                  @click="copyToClipboard(isDe ? mailLdeDe : mailLdeFr)"
                >
                  <template #left-icon>
                    <UilEnvelope />
                  </template>
                </ButtonClassic>
              </template>
            </div>
          </div>
        </section>
      </template>
      <div
        v-else
        class="loader flex-vcenter"
      >
        <InfiniteLoader />
      </div>
    </div>

    <div id="middle_zone">
      <section
        id="others_services"
        class="grid-zone"
      >
        <template v-if="!allServicesAreLoading">
          <template v-if="servicesToDisplay.other && servicesToDisplay.other.length">
            <ButtonService
              v-for="service in servicesToDisplay.other"
              :key="service.code"
              :service="service"
              color="white"
            />
          </template>
        </template>
        <div
          v-else
          class="loader"
        >
          <InfiniteLoader />
        </div>
      </section>

      <div class="grid-zone">
        <LdeHelp
          v-bind="helpAttributes"
          :text="$t('accueil.vous-avez-question')"
          @click="handleActionHelp()"
        />
      </div>
    </div>

    <!-- TODO: à remettre plus tard, pas utilisé pour le moment -->
    <!-- <section id="actualites">
      <h2 class="s5 title-separator">
      <span class="bold">{{ $t('accueil.actualites',) }}</span>
      </h2>
      <div class="grid-zone">
      <Article
      v-for="article in articles"
      :key="article.id"
      :article="article"
      :reduced="article.small"
      />
      </div>
      </section> -->
  </div>

  <Error
    v-else-if="!allServicesAreLoading && (!services || !services.length)"
    variant="no_services"
  />
</template>

<script>
import {
  ButtonClassic,
  ButtonService,
  LdeHelp,
  InfiniteLoader,
  copyToClipboard,
} from "@lde/core_lde_vue";

// import Article from "@/components/Article.vue";
import Error from "@/components/Error.vue";
import { UilEnvelope } from "@iconscout/vue-unicons";

import { mapGetters } from "vuex";

import config from "@/config";

/**
 * Page d'accueil du service.
 */
export default {
  name: "Home",
  components: {
    ButtonClassic,
    ButtonService,
    LdeHelp,
    // Article,
    Error,
    InfiniteLoader,
    UilEnvelope,
  },
  data() {
    return {
      hotlineLink: process.env.VUE_APP_HOTLINE_URL,
      // TODO: données provisoires pour les tests à supprimer quand on aura le back
      articles: [
        {
          id: 1,
          title: "Bienvenue au Kamoulox. Gilles, à vous la main&nbsp;!",
          content: "Je vais chez Dark Vador et je mets du munster sous son casque.",
          date: "20/01/2005",
          link: "",
          tag: "Super",
          image: "https://i.ytimg.com/vi/vv4s5Y2dO8I/hqdefault.jpg",
          small: false,
        },
        {
          id: 2,
          title: "Bonne année !",
          content: "Si date du jour === 1er janvier, alors incrémenter 1 à l'année en cours.",
          date: "31/12/2021",
          link: "",
          tag: "Génial",
          image: "https://img1.bonnesimages.com/bi/bonne-annee/bonne-annee_076d.jpg",
          small: false,
        },
        {
          id: 3,
          title: "Citation du jour",
          content: "\"Lorem ipsum dolor sit amet\".<br />- Albert Einstein",
          date: "25/06/1995",
          link: "",
          tag: "Moins bien",
          image: "https://www.humanite.fr/sites/default/files/images/capture_35.jpg",
          small: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["user", "services", "country", "allServicesAreLoading"]),
    isDe() {
      return this.country === "de";
    },
    helpAttributes() {
      if (this.services.some((service) => service.code.includes("faq"))) {
        return {
          baliseType: "a",
          extLink: config.urlFr.faq,
          buttonLabel: this.$t("help.la-reponse-est-ici"),
        };
      }
      return { buttonLabel: this.isDe ? this.mailLdeDe : this.mailLdeFr };
    },
    mailLdeDe() {
      return config.mailsDe.lde;
    },
    mailLdeFr() {
      return config.mailsFr.lde;
    },
    servicesToDisplay() {
      // Services qui ne sont pas dans le dropdown
      const servicesInPage = this.services.filter((service) => !config.servicesInDropdown.includes(service.groupe));

      const services = {
        main: servicesInPage.filter((service) => ["cristal_commandes", "poplab"].includes(service.groupe)),
        other: servicesInPage.filter((service) => !["cristal_commandes", "poplab"].includes(service.groupe)),
      };

      return services;
    },
  },
  methods: {
    copyToClipboard,
    handleActionHelp() {
      if (!this.services.some((service) => service.code.includes("faq"))) {
        this.copyToClipboard(this.isDe ? this.mailLdeDe : this.mailLdeFr);
      }
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_home.scss";
@use "@/assets/styles/_carres_animation.scss";
</style>
